<script>
	import { onMount } from 'svelte';

	export let src = '';
	export let alt = '';
	export let fit = 'thumb';
	export let immediate = false;
	export let width = null;
	export let height = null;
	export let imageWidth = null;
	export let imageHeight = null;
	export let bannerImage = false;
	export let sizes = {
		'(min-width: 768px)': '1024',
		'(max-width: 767px)': '640',
		'(max-width: 480px)': '480'
	};
	let className = '';
	export { className as class };

	let defaultSize = '640';
	let srcSetWebP = {};
	let srcSetJPG = {};
	let imgSrc = '';
	let isLoading = false;

	function loadImage() {
		isLoading = true;
		srcSetWebP = {};
		srcSetJPG = {};

		for (const breakpoint in sizes) {
			srcSetWebP[breakpoint] = `${src}?w=${sizes[breakpoint]}&fm=webp&fit=${fit}&q=99`;
			srcSetJPG[breakpoint] = `${src}?w=${sizes[breakpoint]}&fm=jpg&fit=${fit}&q=99`;
		}

		imgSrc =
			width && height
				? `${src}?w=${width}&h=${height}&fm=jpg&fit=${fit}&q=99`
				: `${src}?w=${defaultSize}&fm=jpg&fit=${fit}&q=99`;
	}

	$: src && loadImage();

	onMount(() => {
		if (imgSrc) {
			isLoading = false;
		}
	});
</script>

<div class="image-container">
	{#if isLoading}
		<div class="loading-bar" />
	{/if}
	<picture>
		{#if !(width && height)}
			{#each Object.entries(sizes) as [breakpoint]}
				<source media={breakpoint} srcset={srcSetWebP[breakpoint]} type="image/webp" />
				<source media={breakpoint} srcset={srcSetJPG[breakpoint]} type="image/jpeg" />
			{/each}
		{/if}

		<img
			class:banner-image={bannerImage}
			class={className}
			src={imgSrc}
			{alt}
			loading={immediate ? 'eager' : 'lazy'}
			width={width || imageWidth}
			height={height || imageHeight}
			on:load={() => (isLoading = false)}
		/>
	</picture>
</div>

<style>
	img {
		max-width: 100%;
		height: auto;
	}

	.image-container {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.loading-bar {
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgb(0, 174, 255);
		z-index: 1;
		height: 2px;
		animation: loading 1.5s infinite;
		animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
		will-change: width;
	}

	@keyframes loading {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}

	.banner-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100% !important;
		object-fit: cover;
	}
</style>
